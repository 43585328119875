<template>
<div class="portfolio">
    <h3>C.G. Portfolio Made With Blender</h3>

<b-carousel
    id="carousel-fade"
    style="text-shadow: 0px 0px 2px #000"
    fade
    indicators
    img-width="1024"
    img-height="480"
  >
    <b-carousel-slide
      caption="The Three Musketeer"
      img-src="http://isidorovenuto.altervista.org/img/carousel/TheThreeMusketeer.jpg"
    ></b-carousel-slide>
    <b-carousel-slide
      caption="Halloween"
      img-src="http://isidorovenuto.altervista.org/img/carousel/Halloween.jpg"
    ></b-carousel-slide>
    <b-carousel-slide
      caption="A Touch Of Evil"
      img-src="http://isidorovenuto.altervista.org/img/carousel/ATouchOfEvil.jpg"
    ></b-carousel-slide>
        <b-carousel-slide
      caption="Twins Colt"
      img-src="http://isidorovenuto.altervista.org/img/carousel/TwinsColt.jpg"
    ></b-carousel-slide>
        <b-carousel-slide
      caption="Eye"
      img-src="http://isidorovenuto.altervista.org/img/carousel/Eye.jpg"
    ></b-carousel-slide>
      <b-carousel-slide
      caption="Randy Rhoads' Guitar"
      img-src="http://isidorovenuto.altervista.org/img/carousel/RandyRhoadsGuitar.jpg"
    ></b-carousel-slide>
    <b-carousel-slide
      caption="Werewolf"
      img-src="http://isidorovenuto.altervista.org/img/carousel/Werewolf.jpg"
    ></b-carousel-slide>
    <b-carousel-slide
      caption="Spartan Shield"
      img-src="http://isidorovenuto.altervista.org/img/carousel/Scudo.jpg"
    ></b-carousel-slide>
    <b-carousel-slide
      caption="3Doors"
      img-src="http://isidorovenuto.altervista.org/img/carousel/Doors.jpg"
    ></b-carousel-slide>
    <b-carousel-slide
      caption="Dragon"
      img-src="http://isidorovenuto.altervista.org/img/carousel/Dragon.jpg"
    ></b-carousel-slide>
    <b-carousel-slide
      caption="Crusade"
      img-src="http://isidorovenuto.altervista.org/img/carousel/Crusade.jpg"
    ></b-carousel-slide>
    <b-carousel-slide
      caption="AngelDevil"
      img-src="http://isidorovenuto.altervista.org/img/carousel/AngelDevil.jpg"
    ></b-carousel-slide>
    <b-carousel-slide
      caption="Spartan Chess"
      img-src="http://isidorovenuto.altervista.org/img/carousel/SpartanChess.jpg"
    ></b-carousel-slide>
    <b-carousel-slide
      caption="Spartan Logo"
      img-src="http://isidorovenuto.altervista.org/img/carousel/Spartan.jpg"
    ></b-carousel-slide>
    <b-carousel-slide
      caption="Alien"
      img-src="http://isidorovenuto.altervista.org/img/carousel/Alien.jpg"
    ></b-carousel-slide>
    <b-carousel-slide
      caption="Shadow Of A Murder"
      img-src="http://isidorovenuto.altervista.org/img/carousel/ShadowOfMurder.jpg"
    ></b-carousel-slide>
    <b-carousel-slide
      caption="Pomelia"
      img-src="http://isidorovenuto.altervista.org/img/carousel/Pomelia.jpg"
    ></b-carousel-slide>
    <b-carousel-slide
      caption="Vases"
      img-src="http://isidorovenuto.altervista.org/img/carousel/Vasi.jpg"
    ></b-carousel-slide>
    <b-carousel-slide
      caption="Rose In The Vase"
      img-src="http://isidorovenuto.altervista.org/img/carousel/Vaso.jpg"
    ></b-carousel-slide>
    <b-carousel-slide
      caption="Pillows"
      img-src="http://isidorovenuto.altervista.org/img/carousel/CusciniAO.jpg"
    ></b-carousel-slide>
    <b-carousel-slide
      caption="Star Wars Starfighter"
      img-src="http://isidorovenuto.altervista.org/img/carousel/StarWarsStarfighter.jpg"
    ></b-carousel-slide>
    <b-carousel-slide
      caption="Star Wars Assets"
      img-src="http://isidorovenuto.altervista.org/img/carousel/StarWars.jpg"
    ></b-carousel-slide>
    <b-carousel-slide
      caption="Butterfly"
      img-src="http://isidorovenuto.altervista.org/img/carousel/Butterfly.jpg"
    ></b-carousel-slide>
    <b-carousel-slide
      caption="Palio"
      img-src="http://isidorovenuto.altervista.org/img/carousel/Palio.jpg"
    ></b-carousel-slide>
    <b-carousel-slide
      caption="Masquerade"
      img-src="http://isidorovenuto.altervista.org/img/carousel/Masquerade.jpg"
    ></b-carousel-slide>
    </b-carousel>


    <GalleryComponent/>
</div>
</template>


<script>
import GalleryComponent from '@/components/GalleryComponent.vue'

export default {
  name: 'CGPortfolio',
  components: {
    GalleryComponent
  }
}
</script>