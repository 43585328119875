<template>
 <div>
  
</div>
</template>

<script>
  export default {
    name: 'GalleryComponent',
    props:{
    }
  }
</script>